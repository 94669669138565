var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row my-4 mx-1 justify-content-center"},[(_vm.progress)?_c('v-container',{staticClass:"loding",attrs:{"type":"image"}},[_c('v-skeleton-loader',{attrs:{"type":"image"}})],1):_c('div',{staticClass:"col-lg-8 col-sm-12 col-md-10 borderPointier"},[_c('div',{staticClass:"background-white"},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"position"},[_vm._v(_vm._s(_vm.$t("ADRESSE_TRAVAUX")))])])]),_c('form',{staticClass:"my-4"},[_c('div',{staticClass:"my-3"},[_c('b-form-checkbox',{staticClass:"custom-input",attrs:{"label":_vm.$t('QUESTION_ADRESSE')},on:{"change":_vm.handleCheckboxChange},model:{value:(_vm.isSameTravauxAddress),callback:function ($$v) {_vm.isSameTravauxAddress=$$v},expression:"isSameTravauxAddress"}},[_vm._v(" "+_vm._s(_vm.$t("MEME_ADRESSE_TRAVAUX"))+" ")])],1),(_vm.isSameTravauxAddress)?_c('div',[_c('b-form-group',{attrs:{"label":_vm.$t('TRAVAUX_RUE')}},[_c('b-form-input',{staticClass:"form-input custom-placeholder custom-input",attrs:{"value":_vm.getdevisMonoFiche.data.devis.rue_travaux,"placeholder":_vm.$t('TRAVAUX_RUE'),"readonly":""}})],1),_c('b-form-group',{attrs:{"label":_vm.$t('TRAVAUX_CP')}},[_c('b-form-input',{staticClass:"form-input custom-placeholder custom-input",attrs:{"value":_vm.getdevisMonoFiche.data.devis.cp_travaux,"placeholder":_vm.$t('TRAVAUX_CP'),"readonly":""}})],1),_c('b-form-group',{attrs:{"label":_vm.$t('TRAVAUX_VILLE')}},[_c('b-form-input',{staticClass:"form-input custom-placeholder custom-input",attrs:{"value":_vm.getdevisMonoFiche.data.devis.ville_travaux,"placeholder":_vm.$t('TRAVAUX_VILLE'),"readonly":""}})],1)],1):_c('div',[_c('b-form-group',{attrs:{"label":_vm.$t('TRAVAUX_RUE')}},[_c('b-form-input',{staticClass:"form-input custom-placeholder custom-input",attrs:{"placeholder":_vm.$t('TRAVAUX_RUE')},on:{"input":function($event){return _vm.updateDevis(
                  'rue_travaux',
                  _vm.getdevisMonoFiche.data.devis.rue_travaux
                )}},model:{value:(_vm.getdevisMonoFiche.data.devis.rue_travaux),callback:function ($$v) {_vm.$set(_vm.getdevisMonoFiche.data.devis, "rue_travaux", $$v)},expression:"getdevisMonoFiche.data.devis.rue_travaux"}})],1),_c('b-form-group',{attrs:{"label":_vm.$t('TRAVAUX_CP')}},[_c('b-form-input',{staticClass:"form-input custom-placeholder custom-input",attrs:{"placeholder":_vm.$t('TRAVAUX_CP')},on:{"input":function($event){return _vm.updateDevis(
                  'cp_travaux',
                  _vm.getdevisMonoFiche.data.devis.cp_travaux
                )}},model:{value:(_vm.getdevisMonoFiche.data.devis.cp_travaux),callback:function ($$v) {_vm.$set(_vm.getdevisMonoFiche.data.devis, "cp_travaux", $$v)},expression:"getdevisMonoFiche.data.devis.cp_travaux"}})],1),_c('b-form-group',{attrs:{"label":_vm.$t('TRAVAUX_VILLE')}},[_c('b-form-input',{staticClass:"form-input custom-placeholder custom-input",attrs:{"placeholder":_vm.$t('TRAVAUX_VILLE')},on:{"input":function($event){return _vm.updateDevis(
                  'ville_travaux',
                  _vm.getdevisMonoFiche.data.devis.ville_travaux
                )}},model:{value:(_vm.getdevisMonoFiche.data.devis.ville_travaux),callback:function ($$v) {_vm.$set(_vm.getdevisMonoFiche.data.devis, "ville_travaux", $$v)},expression:"getdevisMonoFiche.data.devis.ville_travaux"}})],1)],1)])])],1),_c('div',{staticClass:"d-flex justify-content-end mt-4"},[_c('b-button',{staticClass:"button-pre-style m-3",on:{"click":_vm.goToPrevStep}},[_vm._v(" Précédent ")]),_c('b-button',{staticClass:"button-suivant-style m-3",attrs:{"type":"submit"},on:{"click":_vm.goToNextStep}},[_vm._v(" Suivant "),(_vm.isLoadingButton)?_c('div',{staticClass:"loading ml-2"},[_c('div',{staticClass:"spinner-border spinner-border-sm",attrs:{"role":"status"}})]):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }