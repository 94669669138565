<template>
  <div>
    <div class="row my-4 mx-1 justify-content-center">
      <v-container v-if="progress" type="image" class="loding">
        <v-skeleton-loader type="image"></v-skeleton-loader>
      </v-container>
      <div v-else class="col-lg-8 col-sm-12 col-md-10 borderPointier">
        <div class="background-white">
          <div class="title">
            <div class="position">{{ $t("ADRESSE_TRAVAUX") }}</div>
          </div>
        </div>
        <form class="my-4">
          <div class="my-3">
            <b-form-checkbox
              v-model="isSameTravauxAddress"
              @change="handleCheckboxChange"
              :label="$t('QUESTION_ADRESSE')"
              class="custom-input"
            >
              {{ $t("MEME_ADRESSE_TRAVAUX") }}
            </b-form-checkbox>
          </div>

          <!-- Affichage des données du client quand checkbox est cochée -->
          <div v-if="isSameTravauxAddress">
            <b-form-group :label="$t('TRAVAUX_RUE')">
              <b-form-input
                :value="getdevisMonoFiche.data.devis.rue_travaux"
                :placeholder="$t('TRAVAUX_RUE')"
                class="form-input custom-placeholder custom-input"
                readonly
              ></b-form-input>
            </b-form-group>

            <b-form-group :label="$t('TRAVAUX_CP')">
              <b-form-input
                :value="getdevisMonoFiche.data.devis.cp_travaux"
                :placeholder="$t('TRAVAUX_CP')"
                class="form-input custom-placeholder custom-input"
                readonly
              ></b-form-input>
            </b-form-group>

            <b-form-group :label="$t('TRAVAUX_VILLE')">
              <b-form-input
                :value="getdevisMonoFiche.data.devis.ville_travaux"
                :placeholder="$t('TRAVAUX_VILLE')"
                class="form-input custom-placeholder custom-input"
                readonly
              ></b-form-input>
            </b-form-group>
          </div>
          <div v-else>
            <b-form-group :label="$t('TRAVAUX_RUE')">
              <b-form-input
                v-model="getdevisMonoFiche.data.devis.rue_travaux"
                :placeholder="$t('TRAVAUX_RUE')"
                class="form-input custom-placeholder custom-input"
                @input="
                  updateDevis(
                    'rue_travaux',
                    getdevisMonoFiche.data.devis.rue_travaux
                  )
                "
              ></b-form-input>
            </b-form-group>

            <b-form-group :label="$t('TRAVAUX_CP')">
              <b-form-input
                v-model="getdevisMonoFiche.data.devis.cp_travaux"
                :placeholder="$t('TRAVAUX_CP')"
                class="form-input custom-placeholder custom-input"
                @input="
                  updateDevis(
                    'cp_travaux',
                    getdevisMonoFiche.data.devis.cp_travaux
                  )
                "
              ></b-form-input>
            </b-form-group>

            <b-form-group :label="$t('TRAVAUX_VILLE')">
              <b-form-input
                v-model="getdevisMonoFiche.data.devis.ville_travaux"
                :placeholder="$t('TRAVAUX_VILLE')"
                class="form-input custom-placeholder custom-input"
                @input="
                  updateDevis(
                    'ville_travaux',
                    getdevisMonoFiche.data.devis.ville_travaux
                  )
                "
              ></b-form-input>
            </b-form-group>
          </div>
        </form>
      </div>
    </div>
    <div class="d-flex justify-content-end mt-4">
      <b-button @click="goToPrevStep" class="button-pre-style m-3">
        Précédent
      </b-button>
      <b-button
        type="submit"
        @click="goToNextStep"
        class="button-suivant-style m-3"
      >
        Suivant
        <div class="loading ml-2" v-if="isLoadingButton">
          <div class="spinner-border spinner-border-sm" role="status"></div>
        </div>
      </b-button>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      originalTravauxAddress: {
        rue: null,
        cp: null,
        ville: null,
      },
    };
  },
  props: {
    isLoadingButton: {
      type: Boolean,
      default: false,
    },
    progress: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["getdevisMonoFiche"]),
    isSameTravauxAddress: {
      get() {
        return this.getdevisMonoFiche.data.devis.same_travaux_address === 1;
      },
      set(value) {
        this.getdevisMonoFiche.data.devis.same_travaux_address = value ? 1 : 0;
        this.handleCheckboxChange();
      },
    },
  },
  mounted() {
    this.originalTravauxAddress = {
      rue: this.getdevisMonoFiche.data.devis.rue_travaux || null,
      cp: this.getdevisMonoFiche.data.devis.cp_travaux || null,
      ville: this.getdevisMonoFiche.data.devis.ville_travaux || null,
    };
  },
  methods: {
    ...mapActions(["setDevisMonoFiche"]),
    goToNextStep() {
      this.handleCheckboxChange();
      this.$emit("changeStep", 5, true);
    },
    goToPrevStep() {
      this.$emit("changeStep", 3);
    },
    updateDevis(field, value) {
      this.getdevisMonoFiche.data.devis[field] = value;
      this.setDevisMonoFiche(this.getdevisMonoFiche);
    },
    handleCheckboxChange() {
      if (this.isSameTravauxAddress) {
        // Copier les valeurs du client dans les travaux
        this.getdevisMonoFiche.data.devis.rue_travaux =
          this.getdevisMonoFiche.data.devis.client.rue || "";
        this.getdevisMonoFiche.data.devis.cp_travaux =
          this.getdevisMonoFiche.data.devis.client.cp || "";
        this.getdevisMonoFiche.data.devis.ville_travaux =
          this.getdevisMonoFiche.data.devis.client.ville || "";
      } else {
        // Enregistrer les valeurs saisies par l'utilisateur
        this.updateDevis(
          "rue_travaux",
          this.getdevisMonoFiche.data.devis.rue_travaux
        );
        this.updateDevis(
          "cp_travaux",
          this.getdevisMonoFiche.data.devis.cp_travaux
        );
        this.updateDevis(
          "ville_travaux",
          this.getdevisMonoFiche.data.devis.ville_travaux
        );
      }

      // Enregistrer les données après la mise à jour
      this.setDevisMonoFiche(this.getdevisMonoFiche);
    },
  },
};
</script>

<style scoped>
.borderPointier {
  border: 2px dashed #24316d;
  position: relative;
}
.custom-placeholder::placeholder {
  font-size: 13px;
}

.form-input {
  font-size: 13px;
  height: 41.1px !important;
}
.background-white {
  display: inline-block;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  position: absolute;
  top: -1rem;
  left: 0;
  right: 0;
  z-index: 1;
  text-align: center;
}
.custom-input {
  box-shadow: none;
}
.title {
  color: #24316d;
  position: relative;
  z-index: 2;
  margin: 0;
}

.position {
  background-color: white;
  display: inline-block;
  padding: 0.05rem 2rem;
}
.small-spinner .spinner-border {
  width: 1rem;
  height: 1rem;
}
</style>
