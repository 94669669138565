<template>
  <div class="">
    <div class="row mt-4">
      <!-- First Column -->

      <div class="col-md-6 col-lg-6 col-sm-12">
        <v-container v-if="progress" type="image" class="loding">
          <v-skeleton-loader type="image"></v-skeleton-loader>
          <v-skeleton-loader type="image"></v-skeleton-loader>
          <v-skeleton-loader type="image"></v-skeleton-loader>
        </v-container>
        <form v-else>
          <div class="background-white mt-3">
            <div class="title">
              <div class="position">Règlement</div>
            </div>
          </div>
          <div class="center borderPointier mt-3 p-3">
            <div class="">
              <div class="fieldset mt-3">
                <div class="legend mb-2">{{ $t("MOD_PAYMENT") }}</div>
                <b-form-select
                  v-model="getdevisMonoFiche.data.devis.moyens_paiement"
                  :options="optionspaiement"
                  text-field="text"
                  value-field="value"
                  required
                  class="form-input custom-placeholder custom-input"
                ></b-form-select>
              </div>
            </div>
            <div
              v-if="
                getdevisMonoFiche.data.devis.moyens_paiement === 'financement'
              "
            >
              <div class="row mt-2">
                <div class="fieldset col-4">
                  <div class="legend mb-2">
                    {{ $t("ORG_PRETEUR") }}
                  </div>
                  <b-form-input
                    type="number"
                    v-model="getdevisMonoFiche.data.devis.organisme_preteur"
                    @input="
                      updateDevis(
                        'organisme_preteur',
                        getdevisMonoFiche.data.devis.organisme_preteur
                      )
                    "
                    :placeholder="$t('ORG_PRETEUR')"
                    class="form-input custom-placeholder custom-input"
                  >
                  </b-form-input>
                </div>
                <div class="fieldset col-4">
                  <div class="legend mb-2">
                    {{ $t("MONTANT_CREDIT") }}
                  </div>
                  <b-form-input
                    v-model="getdevisMonoFiche.data.devis.montant_credit"
                    type="number"
                    @input="
                      updateDevis(
                        'montant_credit',
                        getdevisMonoFiche.data.devis.montant_credit
                      )
                    "
                    :placeholder="$t('MONTANT_CREDIT')"
                    class="form-input custom-placeholder custom-input"
                  >
                  </b-form-input>
                </div>
                <div class="fieldset col-4">
                  <div class="legend mb-2">
                    {{ $t("TEAUX_NOMINAL") }}
                  </div>
                  <b-form-input
                    type="number"
                    v-model="getdevisMonoFiche.data.devis.taux_nominal"
                    @input="
                      updateDevis(
                        'taux_nominal',
                        getdevisMonoFiche.data.devis.taux_nominal
                      )
                    "
                    :placeholder="$t('TEAUX_NOMINAL')"
                    class="form-input custom-placeholder custom-input"
                  >
                  </b-form-input>
                </div>
              </div>
              <div class="row">
                <div class="fieldset col-6">
                  <div class="legend mb-2">
                    {{ $t("MENSUALITE") }}
                  </div>
                  <b-form-input
                    type="number"
                    v-model="getdevisMonoFiche.data.devis.mensualite"
                    @input="
                      updateDevis(
                        'mensualite',
                        getdevisMonoFiche.data.devis.mensualite
                      )
                    "
                    :placeholder="$t('MENSUALITE')"
                    class="form-input custom-placeholder custom-input"
                  >
                  </b-form-input>
                </div>
                <div class="fieldset col-6">
                  <div class="legend mb-2">
                    {{ $t("NBR_MENSUALITE") }}
                  </div>
                  <b-form-input
                    type="number"
                    v-model="getdevisMonoFiche.data.devis.nombre_mensualites"
                    @input="
                      updateDevis(
                        'nombre_mensualites',
                        getdevisMonoFiche.data.devis.nombre_mensualites
                      )
                    "
                    :placeholder="$t('NBR_MENSUALITE')"
                    class="form-input custom-placeholder custom-input"
                  >
                  </b-form-input>
                </div>
              </div>
              <div class="row">
                <div class="fieldset col-6">
                  <div class="legend mb-2">
                    {{ $t("TAEG") }}
                  </div>
                  <b-form-input
                    type="number"
                    v-model="getdevisMonoFiche.data.devis.taeg"
                    @input="
                      updateDevis('taeg', getdevisMonoFiche.data.devis.taeg)
                    "
                    :placeholder="$t('TAEG')"
                    class="form-input custom-placeholder custom-input"
                  >
                  </b-form-input>
                </div>
                <div class="fieldset col-6">
                  <div class="legend mb-2">
                    {{ $t("CRIDIT_TOTALE") }}
                  </div>
                  <b-form-input
                    type="number"
                    v-model="getdevisMonoFiche.data.devis.cout_total_credit"
                    @input="
                      updateDevis(
                        'cout_total_credit',
                        getdevisMonoFiche.data.devis.cout_total_credit
                      )
                    "
                    :placeholder="$t('CRIDIT_TOTALE')"
                    class="form-input custom-placeholder custom-input"
                  >
                  </b-form-input>
                </div>
              </div>
            </div>
            <b-form-group label="Debut travaux">
              <b-form-input
                type="date"
                v-model="getdevisMonoFiche.data.devis.debut_travaux"
                required
                class="form-input custom-placeholder custom-input"
                @input="
                  updateDevis(
                    'debut_travaux',
                    getdevisMonoFiche.data.devis.debut_travaux
                  )
                "
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Fin travaux">
              <b-form-input
                type="date"
                v-model="getdevisMonoFiche.data.devis.fin_travaux"
                required
                @input="
                  updateDevis(
                    'fin_travaux',
                    getdevisMonoFiche.data.devis.fin_travaux
                  )
                "
                class="form-input custom-placeholder custom-input"
              ></b-form-input>
            </b-form-group>
          </div>
        </form>
      </div>

      <!-- Second Column -->
      <div class="col-md-6 col-lg-6 col-sm-12">
        <v-container v-if="progress" type="image" class="loding">
          <v-skeleton-loader type="image"></v-skeleton-loader>
        </v-container>

        <form v-else>
          <div class="background-white mt-3">
            <div class="title">
              <div class="position">{{ $t("DELEGATAIRE") }}</div>
            </div>
          </div>
          <div class="borderPointier mt-3 pt-3 px-3">
            <b-form-group :label="$t('DELEGATAIRE')">
              <multiselect
                v-model="selecteddelegataire"
                :searchable="true"
                :close-on-select="true"
                :multiple="false"
                :options="getAllDelegataire"
                label="nom"
                track-by="id"
                :placeholder="$t('DELEGATAIRE')"
                class="form-input custom-placeholder custom-input"
              />
            </b-form-group>
          </div>
          <v-container v-if="progress" type="image" class="loding">
            <v-skeleton-loader type="image"></v-skeleton-loader>
          </v-container>
          <div v-else class="borderPointier mt-3 pt-3 px-3">
            <div class="background-white">
              <div class="title">
                <div class="position">{{ $t("interlocuteur") }}</div>
              </div>
            </div>

            <b-form-group :label="$t('interlocuteur')">
              <multiselect
                v-model="selectedinterlocuteur"
                :searchable="true"
                :close-on-select="true"
                :multiple="false"
                :options="getAllUser"
                label="nom"
                track-by="id"
                :placeholder="$t('interlocuteur')"
                class="form-input custom-placeholder custom-input"
              />
            </b-form-group>
          </div>
        </form>
      </div>
    </div>
    <!--button-->
    <div class="d-flex justify-content-end mt-4">
      <b-button @click="goToPrevStep" class="button-pre-style m-3">
        Précédent
      </b-button>
      <b-button
        type="submit"
        @click="goToNextStep"
        class="button-suivant-style m-3"
      >
        Suivant
        <div class="loading ml-2" v-if="isLoadingButton">
          <div class="spinner-border spinner-border-sm" role="status"></div>
        </div>
      </b-button>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    isLoadingButton: {
      type: Boolean,
      default: false,
    },
    progress: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {},
      moyens_paiement: "financement",
      delegataire: false,
      interlocuteur: false,

      optionspaiement: [
        { value: "financement", text: this.$t("FINANCEMENT") },
        {
          value: "cheque_virement_espece",
          text: this.$t("Cheque_virement_espece"),
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["getdevisMonoFiche", "getAllUser", "getAllDelegataire"]),
    devisData() {
      return this.getdevisMonoFiche?.data?.devis || {};
    },
    selecteddelegataire: {
      get() {
  if (typeof this.delegataire === "object" && this.delegataire !== null) {
    return this.delegataire;
  } else {
    const foundgetAllDelegataire = this.getAllDelegataire.find(option => option.id === this.delegataire);
    return foundgetAllDelegataire;
  }
},

      set(value) {
        this.delegataire = value ? value.id : null;
        this.getdevisMonoFiche.data.devis.delegataire_id = value
          ? value.id
          : null;
        this.getdevisMonoFiche.data.devis.delegataire = {
          id: value.id,
          nom: value.nom,
        };
        this.setDevisMonoFiche(this.getdevisMonoFiche);
      },
    },
    selectedinterlocuteur: {
      get() {
        if (
          typeof this.interlocuteur === "object" &&
          this.interlocuteur !== null
        ) {
          return this.interlocuteur;
        } else {
          return this.getAllUser.find(
            (option) => option.id === this.interlocuteur
          );
        }
      },
      set(value) {
        this.interlocuteur = value ? value.id : null;
        this.getdevisMonoFiche.data.devis.interlocuteur_id = value.id;
        this.getdevisMonoFiche.data.devis.interlocuteur = {
          id: value.id,
          nom: value.nom,
        };
        this.setDevisMonoFiche(this.getdevisMonoFiche);
      },
    },
  },

  created() {
    this.all_user({
      page: 1,
      per_page: 10,
    });
    this.allDelegataire({
      page: 1,
      per_page: 10,
    });

    if (this.getdevisMonoFiche.data && this.getdevisMonoFiche.data.devis) {
      this.initializeDateData();
      this.formatDates();
    }
  },

  watch: {
    getdevisMonoFiche: {
      handler(newVal) {
        if (newVal?.data?.devis) {
        
          this.delegataire = newVal.data.devis.delegataire || null;
          this.interlocuteur = newVal.data.devis.interlocuteur || null;
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions(["setDevisMonoFiche", "allDelegataire", "all_user"]),
    goToNextStep() {
      if (
        this.getdevisMonoFiche.data.devis.moyens_paiement ==
        "cheque_virement_espece"
      ) {
        this.getdevisMonoFiche.data.devis.organisme_preteur = "";
        this.getdevisMonoFiche.data.devis.montant_credit = null;
        this.getdevisMonoFiche.data.devis.taux_nominal = null;
        this.getdevisMonoFiche.data.devis.mensualite = null;
        this.getdevisMonoFiche.data.devis.nombre_mensualites = null;
        this.getdevisMonoFiche.data.devis.taeg = null;
        this.getdevisMonoFiche.data.devis.cout_total_credit = null;
        this.setDevisMonoFiche(this.getdevisMonoFiche);
      }
      this.$emit("changeStep", 8, true);
    },
    goToPrevStep() {
      this.$emit("changeStep", 6);
    },
    initializeDateData() {
      const devis = this.getdevisMonoFiche.data.devis;

      if (!devis.debut_travaux) {
        devis.debut_travaux = "";
      }
      if (!devis.fin_travaux) {
        devis.fin_travaux = "";
      }
    },
    selectinterlocuteur(interlocuteur) {
      this.interlocuteur = { ...interlocuteur };
      this.getdevisMonoFiche.data.devis.interlocuteur_id = interlocuteur.id;
    },
    updateDevis(field, value) {
      this.getdevisMonoFiche.data.devis[field] = value;

      this.setDevisMonoFiche(this.getdevisMonoFiche);
    },
    selectdelegataire(delegataire) {
      this.delegataire = { ...delegataire };
      this.getdevisMonoFiche.data.devis.delegataire_id = delegataire.id;
    },
    formatDates() {
      const addDay = 24 * 60 * 60 * 1000;

      if (this.getdevisMonoFiche.data.devis.debut_travaux) {
        const debutDate = new Date(
          this.getdevisMonoFiche.data.devis.debut_travaux
        );
        this.getdevisMonoFiche.data.devis.debut_travaux = new Date(
          debutDate.getTime() + debutDate.getTimezoneOffset() * 60000 + addDay
        )
          .toISOString()
          .slice(0, 10);
      }

      if (this.getdevisMonoFiche.data.devis.fin_travaux) {
        const finDate = new Date(this.getdevisMonoFiche.data.devis.fin_travaux);
        this.getdevisMonoFiche.data.devis.fin_travaux = new Date(
          finDate.getTime() + finDate.getTimezoneOffset() * 60000 + addDay
        )
          .toISOString()
          .slice(0, 10);
      }
    },
  },
};
</script>
<style scoped>
.borderPointier {
  border: 2px dashed #24316d;
  position: relative;
}
.input-style {
  margin-bottom: 1rem;
  border-radius: 4px;
  border: 1px solid #eaebec;
  padding: 0.5rem 0.75rem;
  background-color: #fff;
}

.background-white {
  display: inline-block;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  position: absolute;
  top: -1rem;
  left: 0;
  right: 0;
  z-index: 1;
  text-align: center;
}
.title {
  color: #24316d;
  position: relative;
  z-index: 2;
  margin: 0;
}

.position {
  background-color: white;
  display: inline-block;
  padding: 0.05rem 2rem;
}
.custom-placeholder::placeholder {
  font-size: 13px;
}
.custom-input {
  box-shadow: none;
}
.form-input {
  font-size: 13px;
  height: 41.1px !important;
}
.small-spinner .spinner-border {
  width: 1rem;
  height: 1rem;
}
</style>
